import React from "react"
import { CSSTransition } from "react-transition-group"
import * as styles from "./Accordion.module.scss"

export type AccordionProps = {
  title: string
  category?: string

  /** Enable ssr rendering of content to make visible it for page crawlers. */
  ssrContent?: boolean

  /** Initially open */
  open?: boolean

  /** Title field props */
  titleProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
  children: React.ReactNode
}

const Accordion = (props: AccordionProps) => {
  const initiallyOpen = typeof props.open !== "undefined" && props.open

  const [inited, setInited] = React.useState<boolean>(!props.ssrContent)
  const [open, setOpen] = React.useState<boolean>(initiallyOpen || !inited)

  React.useEffect(() => {
    if (!inited && !initiallyOpen) {
      setOpen(false)
      setTimeout(() => setInited(true), 0)
    }
  }, [])

  const toggle = (): void => {
    setOpen(!open)
  }

  return (
    <>
      <div className={`${styles.container} ${!open ? styles.closed : ""}`}>
        <div className={styles.titleBar}>
          <div className={styles.titleBarText} onClick={toggle} role="button">
            {props.category && <div className={styles.category}>{props.category}</div>}
            <h3 className={styles.title} {...props.titleProps}>
              {props.title}
            </h3>
          </div>
          <button className={styles.toggleButton} onClick={toggle} title={props.title.toString()}></button>
        </div>
        <CSSTransition in={open} classNames="dropdown" timeout={600} unmountOnExit={inited} exit={inited}>
          <div>
            <div className={styles.content}>{props.children}</div>
          </div>
        </CSSTransition>
      </div>
    </>
  )
}

export default Accordion
