import { graphql } from "gatsby"
import React, { useContext } from "react"
import { PageContext } from "../../../context/PageContext"
import { localeExists, slugify } from "../../../utils"
import SubHeading from "../../atoms/SubHeading"
import Accordion from "../../molecules/Accordion"
import DatoStructuredTextBlock from "../DatoStructuredTextBlock"
import * as styles from "./DatoFaq.module.scss"

const DatoFAQ = (props: Queries.DatoFAQFragment) => {
  //console.log("Rendering DatoFAQ", props)
  const { locale, fallbackLocales } = useContext(PageContext)
  return (
    <div className="layout-container" itemScope itemType="https://schema.org/FAQPage">
      <SubHeading text={props.title} />
      {props.text && <p>{props.text}</p>}
      {props.faqCategory?.map((faqCategory, i) => {
        if (!faqCategory) return null
        return (
          <div key={i} className={styles.faqCategory} id={slugify(faqCategory.title)}>
            <h2>{faqCategory.title}</h2>
            {faqCategory.faqItems?.map((faqItem, i) => {
              if (!faqItem?.question) return null
              // piilotetaan piilotetut + jos käännös puuttuu
              if (
                faqItem.hidden === true ||
                (!localeExists(locale, faqItem._allAnswerLocales) &&
                  !fallbackLocales.find(loc => localeExists(loc, faqItem._allAnswerLocales)))
              ) {
                return null
              }
              return (
                <div key={i} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <Accordion
                    title={faqItem.question}
                    category={faqItem.subject ?? undefined}
                    ssrContent
                    titleProps={{ itemProp: "name" }}>
                    <div
                      className={styles.content}
                      itemScope
                      itemProp="acceptedAnswer"
                      itemType="https://schema.org/Answer">
                      <div itemProp="text">
                        <DatoStructuredTextBlock data={faqItem.answer} shiftHeadings={2} />
                      </div>
                    </div>
                  </Accordion>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default DatoFAQ

export const query = graphql`
  fragment DatoFAQ on DatoCmsFaq {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    text
    faqCategory {
      title
      faqItems {
        subject
        hidden
        question
        answer {
          value
          links {
            __typename
            ... on DatoCmsRouteLink {
              ...DatoRouteLink
            }
            ... on DatoCmsExternalUrl {
              ...DatoExternalUrl
            }
            ... on DatoCmsArticleLink {
              ...DatoArticleLink
            }
            ... on DatoCmsProductLink {
              ...DatoProductLink
            }
            ... on DatoCmsProductFinderLink {
              ...DatoProductFinderLink
            }
          }
          blocks {
            __typename
            #...DatoApplicationDownload
            #...DatoArticleFinder
            ...DatoContactCollectionRef
            ...DatoContactLocationBlock
            ...DatoContactPersons
            #...DatoCtaToArticle
            #...DatoCtaToExternalUrl
            #...DatoCtaToExternalUrlRef
            #...DatoCtaToPage
            #...DatoCtaRef
            #...DatoCtaToProduct
            #...DatoCtaToProductFinder
            #...DatoDealerFinder
            ...DatoExternalVideo
            #...DatoFAQ
            #...DatoForm
            #...DatoHighlightToArticle
            #...DatoHighlightToPage
            #...DatoHighlightToProduct
            #...DatoHighlightToProductFinder
            ...DatoIframeBlock
            ...DatoImageGallery
            #...DatoLatestArticles
            ...DatoMediaBlock
            ...DatoMediaWithText
            #...DatoModularContentRef
            #...DatoNumberFacts
            #...DatoPickedArticles
            #...DatoProductFilter
            #...DatoProductFinderLinkBar
            #...DatoProductFinderTabs
            #...DatoSaunaCalculatorBlock
            #...DatoTabularContent
            #...DatoTextBlock
            ...DatoVideoWithText
          }
        }
        _allAnswerLocales {
          locale
        }
      }
    }
  }
`
